.RightClickMenu {
    z-index: 100000;
    position: fixed;
    font-size: 16px;
    width: 200px;
    height: fit-content;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    box-shadow: -2px -2px 8px rgba(0,0,0,.2), -2px 2px 8px rgba(0,0,0,.2), 2px -2px 8px rgba(0,0,0,.2), 2px 2px 8px rgba(0,0,0,.2);
}

.RightClickMenu::before
{
    padding: 0px 8px;
    font-size: 18px;
    content: attr(data-menuname);
}

.RightClickMenu > hr { margin: 0 }

.RightClickMenu > ul {
    list-style: none;
    margin: 0px;
    padding: 4px;
}

.RightClickMenu > ul > li{
    list-style: none;
    border-radius: 4px;
    cursor: pointer;
}
.RightClickMenu > ul > li:hover{
    color: white;
    background-color: #aaaaaa;
}

@media screen and (max-width: 480px) {
    .RightClickMenu > ul > li{
        padding: 8px 8px;
    }
}

@media screen and (min-width: 480px) {
    .RightClickMenu > ul > li{
        padding: 4px 8px;
    }
}