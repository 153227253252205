.SubPageBtnList {
    display: flex;
    gap: 4px;
    margin-bottom: 4px;
    border-bottom: 1px solid #ced4da;
}

.SubPageBtn {
    border-width: 0px;
    background-color: inherit;
    border-style: solid;
    border-color: #ced4da;
    border-radius: 4px 4px 0px 0px;
    padding: 2px 16px;
}

.SubPageBtn.Selected {
    color: var(--tooltip-color);
    background-color: var(--tooltip-bgcolor);
    border-width: 1px 1px 0px 1px;
}