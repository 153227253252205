.FixHeadTable {
    width: 100%;
    overflow: auto;
    background-color: white;
}

.FixHeadTable > table{
    width: 100%;
}

.FixHeadTable > table > thead > tr{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    border-collapse: collapse;
    box-shadow: 0px 0px 1px #dee2e6, 0px 1.5px 0px black, 0 -1px 0px #dee2e6;
}

.FixHeadTable > table > thead > tr > th {
    padding: 2px 4px;
    text-align: center;
    border-collapse: collapse;
    border: 1px solid #dee2e6;
}

.FixHeadTable > table > tbody > tr > td {
    padding: 2px 4px;
    text-align: center;

    border-collapse: collapse;
    border: 1px solid #dee2e6;
}