.BarContainer {
    position: relative;
    padding: 0px!important;
}

.BidBar{
    z-index: -100;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(25,135,84,.3);
}

.AskBar{
    z-index: -100;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(220,53,69,.3);
}